/*
 * Style directives to uglify test environment.
 */

/* stylelint-disable declaration-no-important
.env-test {
  .login-header {
    background-color: $app-test !important;
  }

  .navbar {
    background-color: $app-test !important;
  }

  .sm-branding-logo {
    a {
      background-image: url("assets/sm-logo-48.test.png?v=1");
      width: 48px;
      height: 48px;
    }

    img {
      display: none;
    }
  }
}
/* stylelint-enable declaration-no-important  */
