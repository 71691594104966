/*
 * Application global variables.
 */

// Angular Material custom theme
// See https://material.angular.io/guide/theming for more details.
//
// You can also read https://medium.com/@tomastrajan/the-complete-guide-to-angular-material-themes-4d165a9d24d1
// for more insight about Angular Material theming.


@use '@angular/material' as mat;

@import "./theme-palette.scss";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.

$app-primary: mat.define-palette($smx-suite-primary);
$app-accent: mat.define-palette($smx-suite-accent);
$app-warn: mat.define-palette($smx-suite-warn);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat.define-light-theme(
    (color: (
    primary: $app-primary, 
    accent: $app-accent,
    warn:$app-warn ),
    typography: mat.define-typography-config('Nunito'),
    density: 0));


$app-brand: #f5f5f5;
$app-header: $app-brand;

$app-dev: rgb(0, 161, 48);
$app-test: #000;


// // Emit theme-dependent styles for common features used across multiple components.
// @include mat.core-theme($smx-theme);

// // Emit styles for MatButton based on `$smx-theme`. Because the configuration
// // passed to `define-light-theme` omits typography, `button-theme` will not
// // emit any typography styles.
// @include mat.button-theme($smx-theme);

// // Include the theme mixins for other components you use here.
