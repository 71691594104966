/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */
// @use "@angular/material" as mat;

@use "@angular/material" as mat;

// Import uglified version for dev environment
@import "theme.dev.scss";

// Import uglified version for test environment
@import "theme.test.scss";

// stylelint-disable-next-line selector-max-universal
* {
    box-sizing: border-box;
}

html,
body {
    display: flex;
    flex-direction: row;
    justify-content: flex-start; // era flex-end
    font-size: medium;
    font-family: Nunito, Roboto, sans-serif;
    margin: 0;
    width: 100%;
    height: 100%;

    --mat-app-background-color: white;

    //FONT
    --mdc-typography-font-family: Nunito, Roboto, sans-serif;
    --mdc-typography-font-size: medium;
    --mdc-typography-body-font-size: medium;
    --mat-app-text-color: #333333;
    --mdc-list-list-item-label-text-color: #333333;
    //BOTTONI
    --mdc-filled-button-container-shape: 9999px;
    --mdc-text-button-container-shape: 9999px;
    --mat-standard-button-toggle-shape: 9999px;
    --mdc-outlined-button-container-shape: 9999px;

    //SNACKBAR
    --mdc-snackbar-container-color: #008686;
    --mdc-snackbar-supporting-text-color: white;
    --mat-snack-bar-button-color: white;

    //FORM FIELDS
    .mdc-form-field {
        color: #00000099;
    }

    //DIALOGS
    --mat-dialog-actions-alignment: end;
}

.pdfviewer {
    lib-viewer {
        .box {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}

.hidden,
.error.hidden {
    // Da usare il meno possibile: usa la direttiva hidden di Angular
    display: none;
}

.loader-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.loader {
    width: 200px;
    height: 300px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    margin: auto;
}

.mcc-color-picker-selector-preview {
    height: 100px !important;
}

.message-container {
    display: grid;
    grid-template-rows: auto auto auto;
    margin: auto;
    min-width: 300px;
    padding: 0 0 15px;
}

.message-title {
    grid-row: 1;
    color: white;
    background-color: #2b939e;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    padding: 10px;
}

.message-text {
    grid-row: 2;
    text-align: center;
    font-size: 20px;
    padding: 30px;
    font-family: Nunito, Roboto, sans-serif;
}

.error {
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    background-color: mat.get-color-from-palette($smx-primary, 100);
    z-index: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .error-reload-page {
        background-color: mat.get-color-from-palette($smx-secondary, 600);
        color: white;
        // color:  mat.get-color-from-palette($smx-primary, 900);
        font-family: Nunito, Roboto, sans-serif;
        font-size: 20px;
        text-align: center;
        margin-top: 30px;
    }

    .error-content {
        background-color: mat.get-color-from-palette($smx-primary, 100);
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        width: 200px;
        height: 200px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        background-image: url("/assets/error/ops.png");
        background-repeat: no-repeat;
        padding: 20px;

        .error-text-header {
            color: mat.get-color-from-palette($app-warn);
            font-family: Nunito, Roboto, sans-serif;
            font-weight: bold;
            font-size: 30px;
            margin-top: 0;
        }

        .error-text-message {
            cursor: pointer;
            color: mat.get-color-from-palette($smx-primary, 900);
            font-family: Nunito, Roboto, sans-serif;
            font-size: 18px;
            text-align: center;
            margin-top: 10px;
        }
    }
}



.maintenance-container {
    display: grid;
    grid-template-rows: 113px auto;
    margin: auto;
    width: 100vw;

    .maintenance-brand {
        background-color: white;
        color: mat.get-color-from-palette($app-primary, "default-contrast");
        text-decoration: none;
        font-size: 30px;
        font-weight: bold;
        padding: 0.7em;
        vertical-align: middle;
    }

    .maintenance-main-container {
        height: calc(100vh - 113px);
        grid-row: 2;
        overflow: hidden;
        width: 100vw;
        background-color: #f4f4f4;
        align-content: center;
        justify-content: center;
    }

    .maintenance-image-logo {
        margin: auto;
        margin-top: 50px;
        text-align: center;
    }
}

.mat-mdc-select-panel {
    min-width: 180px !important;
    max-width: 400px !important;
}


.mat-mdc-select-panel-wrap {
    margin-top: 30px !important;
    margin-left: 20px !important;
}

.mat-mdc-select-panel-wrap .mat-mdc-select-panel {
    min-width: calc(100% + 12px) !important;
    transform: translateX(4px) !important;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.language-menu {
    max-height: 200px !important;
    min-width: 420px !important;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
    // min-width: 400px !important;
    max-width: 500px !important;
}

.div.mat-mdc-menu-panel.mat-mdc-menu-panel.language-menu {
    max-height: 200px !important;
    min-width: 420px !important;
}

.mat-mdc-menu-panel {
    &.mouseleave {
        .mat-mdc-menu-content {
            padding: 0;

            >div {
                padding-top: 8px;
                padding-bottom: 8px;
            }
        }
    }


    .cdk-overlay-pane {
        max-height: max-content - 50px //calc(100vh - calc(top + bottom)) !important;
    }
}


.expansion-panel .mat-expansion-panel-body {
    padding: 0 24px 16px;
    margin: auto;
}

.option-background {
    background-image: none !important;
    background-repeat: no-repeat !important;
    background-size: 14px;
    border-style: none !important;
    position: relative;
    transform: none;
    background-color: transparent !important;

}


.option-color {

    border: 1px solid mat.get-color-from-palette($smx-primary, 100) !important;

}

/*.mat-font-icon {
    margin: 1px !important;

    margin-left: -5px !important;
    margin-top: 4px !important;

    width: 22px !important;
    height: 22px !important;
}*/

.mcc-color-picker-remove-color {

    position: relative !important;
    // top: -12px !important;
    border-radius: 12px !important;

    .mcc-color-picker-transparent {
        width: 20px;
        height: 1px;
        border-bottom: 1px solid red;
        transform: translateY(0) translateX(-5px) rotate(45deg);
        -webkit-transform: translateY(0) translateX(-5px) rotate(45deg) !important;
    }

    .h3 {
        font-weight: 400;
    }

}

.cancel-button {
    margin-top: 10px;
    margin-left: 10px;
    width: auto;
    letter-spacing: normal;
    color: black !important;
    font-weight: 400;
}

.ok-button {
    margin-top: 10px;
    background-color: mat.get-color-from-palette($smx-secondary, 600);
    color: white;
    letter-spacing: normal;
}

.ok-button:disabled {
    color: gray;
    background-color: mat.get-color-from-palette($smx-secondary, 100);
    letter-spacing: normal;

}


.custom-item .mdc-list-item__primary-text {
    grid-column: 2;
}

/* stylelint-disable declaration-no-important  */
// // per ridimensionare le dialog
.resizable-container .cdk-overlay-pane {
    width: unset !important;
    height: unset !important;
    max-width: 100% !important;
}

.resizable-container .mat-mdc-dialog-container {
    min-width: 300px !important;
    min-height: 300px !important;
    max-width: 100vw;
    max-height: 100vh !important;
    overflow: hidden;
    resize: both;
}

@media print {

    // @page { size: auto; margin: 0; }
    @page {
        size: auto;
        margin-bottom: 0.5cm;
    }
}

//fix per scrollview con bottoni e tooltip BUG ANGULAR MATERIAL su mobile
.mat-tooltip-trigger {
    touch-action: auto !important;
  }
