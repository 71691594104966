/* For use in src/lib/core/theming/_palette.scss */
//OLD
$smx-primary: (
  50 : #eaeeef,
  100 : #ccd4d7,
  200 : #aab7bd,
  300 : #879aa2,
  400 : #6e848e,
  500 : #546e7a,
  600 : #4d6672,
  700 : #435b67,
  800 : #3a515d,
  900 : #293f4a,
  A100 : #b2ebf2,
  A200 : #62c7ff,
  A400 : #2fb5ff,
  A700 : #15acff,
  contrast: (50 : #000,
    100 : #000,
    200 : #000,
    300 : #000,
    400 : #fff,
    500 : #fff,
    600 : #fff,
    700 : #fff,
    800 : #fff,
    900 : #fff,
    A100 : #000,
    A200 : #000,
    A400 : #000,
    A700 : #000)
);

//OLD
$smx-secondary: (
  50 : #f7e9ea,
  100 : #eac7c9,
  200 : #dca2a6,
  300 : #ce7d82,
  400 : #c46167,
  500 : #b9454c,
  600 : #b23e45,
  700 : #aa363c,
  800 : #a22e33,
  900 : #931f24,
  A100 : #ffcecf,
  A200 : #ff9b9e,
  A400 : #ff686d,
  A700 : #ff4e54,
  contrast: (50 : #000,
    100 : #000,
    200 : #000,
    300 : #000,
    400 : #fff,
    500 : #fff,
    600 : #fff,
    700 : #fff,
    800 : #fff,
    900 : #fff,
    A100 : #000,
    A200 : #000,
    A400 : #000,
    A700 : #000)
);

$smx-suite-primary: (
    50 : #eceeef,
    100 : #cfd4d7,
    200 : #afb7bd,
    300 : #8e9aa3,
    400 : #76858f,
    500 : #5e6f7b,
    600 : #566773,
    700 : #4c5c68,
    800 : #42525e,
    900 : #31404b,
    A100 : #9cd3ff,
    A200 : #69bdff,
    A400 : #36a6ff,
    A700 : #1c9bff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$smx-suite-accent: (
    50 : #e0f3f3,
    100 : #b3e0e0,
    200 : #80cccc,
    300 : #4db8b8,
    400 : #26a8a8,
    500 : #009999,
    600 : #009191,
    700 : #008686,
    800 : #007c7c,
    900 : #006b6b,
    A100 : #9affff,
    A200 : #67ffff,
    A400 : #34ffff,
    A700 : #1affff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$smx-suite-warn: (
    50 : #f6e9ea,
    100 : #eac7c9,
    200 : #dca2a6,
    300 : #cd7d82,
    400 : #c36167,
    500 : #b8454c,
    600 : #b13e45,
    700 : #a8363c,
    800 : #a02e33,
    900 : #911f24,
    A100 : #ffccce,
    A200 : #ff999d,
    A400 : #ff666b,
    A700 : #ff4d53,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$smx-suite-basic: (
    50 : #fdfdfd,
    100 : #fafafa,
    200 : #f6f6f6,
    300 : #f2f2f2,
    400 : #f0f0f0,
    500 : #ededed,
    600 : #ebebeb,
    700 : #e8e8e8,
    800 : #e5e5e5,
    900 : #e0e0e0,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
