/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */
@use "@angular/material" as mat;
// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme/theme-variables";
// Theme customization
@import "theme/theme";
// 3rd party libraries
@import "material-design-icons/iconfont/material-icons.css";

//@import "@angular/material/theming"; tolto per material 17

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
//@include mat.legacy-core(); tolto per material 17

// Angular Material custom theme
// The mixins below must be included once so we separated them from the variables

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);
//@include mat.all-legacy-component-themes($app-theme); tolto per material17

/*.cdk-overlay-container .mat-mdc-menu-panel.ctxMenu {
    max-width: none;
}*/

/*$mdc-typography-styles-button: (
    font-size: 14px,
    text-transform: none,
);*/


@media print {

    // @page { size: auto; margin: 0; }
    @page {
        size: auto;
        /* auto is the initial value */
        margin-bottom: 0.5cm;
        /* this affects the margin in the printer settings */
    }
}

body {
    overflow: hidden;
}

html,
body {
    height: 100%;

}

body {
    margin: 0;
}
