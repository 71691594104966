/*
 * Style directives to uglify test environment.
 */

// $app-dev: rgb(0, 161, 48);

/* stylelint-disable declaration-no-important  */
// .env-dev {
//   .login-header {
//     background-color: $app-dev !important;
//   }

//   .navbar {
//     background-color: $app-dev !important;
//   }

//   // .sm-branding-logo {
//   //   a {
//   //     background-image: url("./src/assets/sm-logo-48.test.png?v=1");
//   //     width: 48px;
//   //     height: 48px;
//   //   }

//   //   img {
//   //     display: none;
//   //   }
//   // }
// }

/* stylelint-enable declaration-no-important  */
